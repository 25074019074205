<template>
  <div class="section" id="editarProjeto">
    <!-- Wizard here -->
    <section class="wizard w-100">
      <div class="">
        <div class="col-md-12">
          <div class="card--1">
            <div class="card-body custom-wizard">
              <vue-good-wizard
                ref="wizard"
                :steps="steps"
                :onNext="nextClicked"
                :onBack="backClicked"
                nextStepLabel="Próximo"
                previousStepLabel="Voltar"
                finalStepLabel="Concluir"
              >
                <div slot="page1">
                  <section>
                    <h3>Editar Projeto</h3>
                    <div class="form-group mt-5">
                      <label class="">Nome:</label>
                      <input
                        type="text"
                        class="form-control"
                        aria-describedby="emailHelp"
                        placeholder="Insira o nome do projeto"
                        v-model="form.name"
                      />
                      <small
                        id="emailHelp"
                        class="form-text text-warning"
                        v-show="showError.name"
                        >O nome do prejeto é obrigatório e deve ter pelo menos 3
                        caracteres.</small
                      >
                    </div>

                    <div class="form-group mt-2">
                      <label class="">Tipo:</label>

                      <b-form-select
                        id="example-input-2"
                        name="example-input-2"
                        :options="tipoProjetos"
                        v-model="form.tipo"
                        aria-describedby="input-2-live-feedback"
                        class="selecte"
                        disabled
                      ></b-form-select>

                      <small
                        id=" "
                        class="form-text text-warning"
                        v-show="showError.tipo"
                        >Campo obrigatório</small
                      >
                    </div>

                    <div class="form-group mt-2">
                      <div>
                        <label class="typo__label">Cliente:</label>
                        <multiselect
                          :multiple="false"
                          selectLabel="Enter para selecionar"
                          selectedLabel="Selecionado"
                          deselectLabel="Enter para remover"
                          v-model="value"
                          :options="options"
                          placeholder="Selecionar Cliente"
                          label="name"
                          track-by="name"
                          @search-change="findClientes"
                          :loading="loaddingClients"
                        >
                        </multiselect>

                        <small
                          class="form-text text-warning"
                          v-show="showError.cliente"
                          >Campo Cliente obrigatório</small
                        >
                      </div>
                    </div>
                  </section>
                </div>
                <div slot="page2">
                  <section>
                    <!-- <h3>Profile</h3> -->
                    <div class="form-group">
                      <label>Início</label>
                      <!-- <input type="date" class="form-control" aria-describedby="emailHelp" placeholder="Data de Início" v-model="teste" /> -->

                      <v-date-picker v-model="form.dateStart" is-dark>
                        <template v-slot="{ inputValue, inputEvents }">
                          <input
                            class="
                              px-2
                              py-1
                              border
                              rounded
                              focus:outline-none focus:border-blue-300
                              d-block
                              w-100
                              input-date
                            "
                            placeholder="Data de Início"
                            :value="inputValue"
                            v-on="inputEvents"
                          />
                        </template>
                      </v-date-picker>

                      <small
                        v-show="showError.dt_inicio"
                        id=""
                        class="form-text text-warning"
                        >Indique a data Início do projeto</small
                      >
                    </div>
                    <div class="form-group">
                      <label>Fim</label>
                      <!-- <input type="date" class="form-control" placeholder="Data Final" v-model="form.dateEnd" /> -->
                      <v-date-picker v-model="form.dateEnd" is-dark color="red">
                        <template v-slot="{ inputValue, inputEvents }">
                          <input
                            class="
                              px-2
                              py-1
                              border
                              rounded
                              focus:outline-none focus:border-blue-300
                              d-block
                              w-100
                              input-date
                            "
                            placeholder="Data de Início"
                            :value="inputValue"
                            v-on="inputEvents"
                          />
                        </template>
                      </v-date-picker>

                      <small
                        v-show="showError.dt_fim"
                        id=""
                        class="form-text text-warning"
                        >Indique a data Final do projeto</small
                      >
                    </div>
                    <div class="form-group">
                      <label>Meta Geral </label>
                      <input
                        type="number"
                        class="form-control"
                        placeholder="Meta de Leads, exemplo: 100"
                        min="0"
                        v-model="form.meta"
                      />

                      <small
                        v-show="showError.metaGeral"
                        id=""
                        class="form-text text-warning"
                        >Indique uma meta geral a ser atingida</small
                      >
                    </div>
                  </section>
                </div>
                <div slot="page3">
                  <section>
                    <h3>Descrição</h3>
                    <div class="form-group">
                      <label>Comentários</label>
                      <textarea
                        class="form-control"
                        rows="3"
                        v-model="form.descricao"
                        placeholder="Opcional"
                      ></textarea>
                    </div>
                  </section>
                </div>
                <div slot="page4">
                  <section>
                    <h3>Revisar Alterações</h3>
                    <!-- <div class="form-check bg-primary">
                      <label class="form-check-label text-muted">
                        <input type="checkbox" class="form-check-input" />
                        I agree with the Terms and Conditions.
                        <i class="input-helper"></i>
                      </label>
                    </div> -->

                    <h5>
                      Nome do Projeto:
                      <span class="ml-2 text-primary">{{ form.name }}</span>
                    </h5>
                    <h5>
                      Tipo:
                      <span class="ml-2 text-primary">{{ form.tipo }}</span>
                    </h5>

                    <h5>
                      Cliente:
                      <span class="ml-2 text-primary">{{ clienteName }}</span>
                    </h5>

                    <h5>
                      Meta Geral
                      <span class="ml-2 text-primary"
                        >{{ form.meta }} <span class="text-info">leads</span>
                      </span>
                    </h5>
                    <h5>
                      Descrição:
                      <span class="ml-2 text-primary">{{
                        form.descricao
                      }}</span>
                    </h5>
                    <h5>
                      Data Inicial:
                      <span class="ml-2 text-primary">{{
                        form.dateFomatedStart.split("-").reverse().join("/")
                      }}</span>
                    </h5>
                    <h5>
                      Data Final:
                      <span class="ml-2 text-primary">{{
                        form.dateFomatedEnd.split("-").reverse().join("/")
                      }}</span>
                    </h5>
                  </section>
                </div>
              </vue-good-wizard>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions } from "vuex";

import { GoodWizard } from "vue-good-wizard";

import projetoService from "../../../services/projeto.service";
import clienteService from "../../../services/cliente.service";

import { getItem } from "@/hooks/useLocalStorage";

export default {
  props: {
    getAllList: {},
    // changeMostrarEditarProjeto:{},
    editItem: {},
  },

  components: {
    // Formulario,
    "vue-good-wizard": GoodWizard,
  },

  data() {
    return {
      steps: [
        {
          label: this.editItem.nome,
          slot: "page1",
        },
        {
          //   label: "Detalhes ",
          label: "Detalhes",
          slot: "page2",
        },
        {
          label: "Descrição",
          slot: "page3",
        },
        {
          label: "Finish",
          slot: "page4",
          options: {
            // nextDisabled: true,
            finalStepLabel: "Finalizar",
          },
        },
      ],

      tipoProjetos: [
        {
          value: null,
          text: "Selecionar...",
        },
        {
          value: "Base de Dados",
          text: "Base de Dados",
        },
        {
          value: "Prospecção",
          text: "Prospecção",
        },
      ],

      form: {
        name: this.editItem.nome,
        // cliente: {

        // },
        tipo: this.editItem.projeto_tipo.descricao,
        dateStart: new Date(),
        dateEnd: new Date(),
        dateFomatedStart: "",
        dateFomatedEnd: "",
        meta: this.editItem.meta,
        descricao: this.editItem.descricao,
      },

      showError: {
        name: false,
        cliente: false,
        tipo: false,
        dt_inicio: false,
        dt_fim: false,
        metaGeral: false,
      },
      options: [],

      value: {},
      clienteName: "",
      loaddingClients: false,
    };
  },

  methods: {
    ...mapActions(["atualizarProjetoSelecionadoAction"]),
    nextClicked(currentPage) {
      const _this = this;

      if (currentPage == 0) {
        let name = this.form.name.trim();
        let tipo = this.form.tipo;
        if (!name || name.length < 3) {
          this.showError.name = true;
        }
        if (tipo == null) {
          this.showError.tipo = true;
        }

        if (!this.value) {
          _this.showError.cliente = true;
        }

        if (name.length >= 3 && tipo && this.value != undefined) {
          this.showError.name = false;
          this.showError.tipo = false;
          _this.showError.cliente = false;

          this.steps[1].label = this.form.name;
          this.steps[2].label = this.form.name;
          this.steps[3].label = this.form.name;

          this.clienteName = this.value.name;

          _this.$refs.wizard.goNext(true);
        }
      }

      //pagina array 1 => label "detalhes2

      if (currentPage == 1) {
        let diaStart = this.form.dateStart
          ? this.form.dateStart.getDate()
          : undefined;
        let mesStart = this.form.dateStart
          ? this.form.dateStart.getMonth() + 1
          : undefined;
        let anoStart = this.form.dateStart
          ? this.form.dateStart.getFullYear()
          : undefined;
        diaStart = diaStart <= 9 ? `0${diaStart}` : diaStart;
        mesStart = mesStart <= 9 ? `0${mesStart}` : mesStart;
        let dt_inicio = `${anoStart}-${mesStart}-${diaStart}`; //usar essa variavel

        // ---------------------------
        let diaEnd = this.form.dateEnd
          ? this.form.dateEnd.getDate()
          : undefined;
        let mesEnd = this.form.dateEnd
          ? this.form.dateEnd.getMonth() + 1
          : undefined;
        let anoEnd = this.form.dateEnd
          ? this.form.dateEnd.getFullYear()
          : undefined;
        diaEnd = diaEnd <= 9 ? `0${diaEnd}` : diaEnd;

        mesEnd = mesEnd <= 9 ? `0${mesEnd}` : mesEnd;
        let dt_fim = `${anoEnd}-${mesEnd}-${diaEnd}`; // usar essa variavel

        // console.log("data inicio: " + dt_inicio);
        // console.log("data final   " + dt_fim);

        if (dt_inicio == "undefined-undefined-undefined") {
          _this.showError.dt_inicio = true;
        }
        if (dt_fim == "undefined-undefined-undefined") {
          _this.showError.dt_fim = true;
        }
        if (!_this.form.meta || _this.form.meta <= 0) {
          _this.showError.metaGeral = true;
        }

        if (
          dt_inicio != "undefined-undefined-undefined" &&
          dt_fim != "undefined-undefined-undefined" &&
          _this.form.meta &&
          _this.form.meta > 0
        ) {
          _this.showError.dt_inicio = false;
          _this.showError.dt_fim = false;
          _this.showError.metaGeral = false;
          _this.$refs.wizard.goNext(true);

          _this.form.dateFomatedStart = dt_inicio;
          _this.form.dateFomatedEnd = dt_fim;
        }
      }

      if (currentPage == 2) {
        //pagina array 2 => label "Descrição"
        _this.$refs.wizard.goNext(true);
      }

      if (currentPage == 3) {
        //  console.log(this.editItem);

        projetoService
          .editProjet(
            this.form,
            this.editItem, //idRora
            this.value.id // idCliente
          )

          .then(() => {
            this.getAllList(); // atualiza a lista na tela
            document.getElementsByClassName("b-sidebar-backdrop")[0].click(); // fecha sidebar
            this.makeToast("success"); // chama mensagem de sucesso
            this.updateProjectSelected();
          })
          .catch((erro) => {
            console.log("erro ao atualizar projeto " + erro);
          });
      }
    },

    backClicked() {
      return true;
    },

    makeToast(variant = null) {
      setTimeout(() => {
        this.$bvToast.toast(`${this.form.name.toUpperCase()}`, {
          title: `Projeto Atualizado`,
          variant: variant,
          solid: true,
        });
      }, 1000);
    },

    findClientes(query) {
      this.loaddingClients = true;
      let params = {
        nome: query,
      };

      clienteService
        .getAll(params)
        .then((res) => {
          let listaClientes = res.data.data;

          //console.log(listaClientes);

          this.options = listaClientes.map((cliente) => {
            return {
              name: cliente.nome,
              id: cliente.id,
            };
          });

          let selected = listaClientes.filter((item) => {
            return item.id === this.editItem.cliente_id;
          });

          this.value = {
            name: selected[0].nome,
            id: selected[0].id,
          };

          this.loaddingClients = false;
        })
        .catch((erro) => {
          console.log("Erro ao obter LISTA DE CLIENTES " + erro);
        });
    },
    updateProjectSelected() {
      let projectSelected = getItem("selected-project");
      // console.log(projectSelected.id);
      // console.log(this.editItem.id);

      if (this.editItem.id == projectSelected.id) {       

        projetoService
          .getAll()
          .then((res) => {

            const projetos = res.data

            const result =  projetos.filter((projeto)=> projeto.id === this.editItem.id)

            if(result.length > 0) {
              this.atualizarProjetoSelecionadoAction(result[0])
            }

            

            
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
  },

  mounted() {
    //console.log(this.editItem);
    this.findClientes();
  },
};
</script>

<style lang="scss" scoped>
.section {
  color: #fff;
  background: #191c24;
  height: 100%;
  display: flex;
  align-items: center;

  /* max-width: 415px;  */
  box-shadow: 0 0 35px -3px #000;
  border-top-left-radius: 6px;

  .forms {
    max-width: 782px;

    h4 {
      font-size: 20px;
      line-height: 28px;
      font-weight: normal;
    }

    .forms-sample {
      #input1 {
        color: white;
        padding-left: 5px;
      }

      #input1::placeholder {
        color: #dcdcdc;
      }

      .my-btn {
        line-height: 50px;
        max-width: 216px;
        padding: 0;
      }
    }
  }
}

.input-date {
  background: #2a3038;
  color: #dcdcdc;
  color: #fff;
}
</style><style lang="scss">
#editarProjeto .custom-wizard .wizard__body[data-v-c21d83ca].vgw-mobile,
#editarProjeto .wizard__arrow {
  background: none;
}

#sidebar-right {
  background: #191c24 !important;
  /* background: #191c24 ; */
}

#editarProjeto .wizard__step__label {
  text-transform: capitalize;
}

#sidebar-right button.close {
  opacity: 1;
  font-size: 33px;
  position: absolute;
  top: 15px;
  left: 15px;
  z-index: 1;

  &:hover {
    opacity: 0.7;
  }
}

#editarProjeto
  .custom-wizard
  .wizard__body__actions
  a.final-step[data-v-c21d83ca] {
  background: var(--primary);
}

#editarProjeto .custom-wizard .pull-left[data-v-c21d83ca] {
  background: none !important;
  margin-right: 10px;
  border-color: var(--secondary) !important;
  transition: 0.6;
  &:hover {
    opacity: 0.7;
  }
}
</style>
