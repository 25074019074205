<template>
  <div class="section" id="novoProjeto">
    <button class="btn closerButton" v-on:click="mostrarNovoProjetoChangeState">
      <span class="mdi mdi-close"></span>
    </button>

    <!-- Wizard here -->
    <section class="wizard w-100 h-100">
      <div class="row h-100">
        <div class="col-md-12">
          <div class="card h-100">
            <div class="card-body custom-wizard">
              <vue-good-wizard
                ref="wizard"
                :steps="steps"
                :onNext="nextClicked"
                :onBack="backClicked"
                nextStepLabel="Próximo"
                previousStepLabel=" Voltar"
                finalStepLabel="Criar Projeto"
              >
                <div slot="page1">
                  <section>
                    <!-- <h3>Vamos começar com um nome para o projeto</h3> -->
                    <h3>Novo Projeto</h3>
                    <!-- <h4 class="">Vamos começar com um nome para o projeto</h4>  -->
                    <div class="form-group mt-5">
                      <label >Nome:</label>
                      <input
                        type="text"
                        class="form-control"
                        aria-describedby="emailHelp"
                        placeholder="Insira o nome do projeto"
                        v-model="form.name"
                      />
                      <small
                        id="emailHelp"
                        class="form-text text-warning"
                        v-show="showError.name"
                        >O nome do prejeto é obrigatório e deve ter pelo menos 3
                        caracteres.</small
                      >
                    </div>

                    <div class="form-group mt-2">
                      <label class="">Tipo:</label>
                      <!-- <input type="text" class="form-control" aria-describedby="emailHelp" placeholder="Insira o nome do projeto" v-model="form.name" /> -->

                      <b-form-select
                        id="example-input-2"
                        name="example-input-2"
                        :options="tipoProjetos"
                        v-model="form.tipo"
                        aria-describedby="input-2-live-feedback"
                        class="selecte"
                      ></b-form-select>

                      <small
                        id=" "
                        class="form-text text-warning"
                        v-show="showError.tipo"
                        >Campo obrigatório</small
                      >
                    </div>

                    <div class="form-group mt-2">
                      <div>
                        <label class="typo__label">Cliente:</label>
                        <multiselect
                          :multiple="false"
                          selectLabel="Enter para selecionar"
                          selectedLabel="Selecionado"
                          deselectLabel="Enter para remover"
                          v-model="form.cliente.name"
                          :options="options"
                          placeholder="Selecionar Cliente"
                          label="name"
                          track-by="name"
                          :loading="loaddingClients"
                          @search-change="findClientes"
                        >
                        </multiselect>

                        <small
                          class="form-text text-warning"
                          v-show="showError.cliente"
                          >Campo Cliente obrigatório</small
                        >

                        <!-- <pre class="language-json"><code>{{ value  }}</code></pre> -->
                      </div>
                    </div>

                    <!-- <div class="form-group">
                                        <label>Password</label>
                                        <input type="password" class="form-control" placeholder="Password">
                                    </div>

                                    <div class="form-group">
                                        <label>Confirm Password</label>
                                        <input type="password" class="form-control" placeholder="Confirm password">
                                    </div> -->
                  </section>
                </div>
                <div slot="page2">
                  <section>
                    <!-- <h3>Profile</h3> -->
                    <div class="form-group">
                      <label>Início</label>
                      <!-- <input type="date" class="form-control" aria-describedby="emailHelp" placeholder="Data de Início" v-model="teste" /> -->

                      <v-date-picker v-model="form.dateStart" >
                        <template v-slot="{ inputValue, inputEvents }">
                          <input
                            class="
                              px-2
                              py-1
                              border
                              rounded
                              focus:outline-none focus:border-blue-300
                              d-block
                              w-100
                              input-date
                            "
                            placeholder="Data de Início"
                            :value="inputValue"
                            v-on="inputEvents"
                          />
                        </template>
                      </v-date-picker>

                      <small
                        v-show="showError.dt_inicio"
                        id=""
                        class="form-text text-warning"
                        >Indique a data Início do projeto</small
                      >
                    </div>
                    <div class="form-group">
                      <label>Fim</label>
                      <!-- <input type="date" class="form-control" placeholder="Data Final" v-model="form.dateEnd" /> -->
                      <v-date-picker v-model="form.dateEnd"  color="red">
                        <template v-slot="{ inputValue, inputEvents }">
                          <input
                            class="
                              px-2
                              py-1
                              border
                              rounded
                              focus:outline-none focus:border-blue-300
                              d-block
                              w-100
                              input-date
                            "
                            placeholder="Data de Início"
                            :value="inputValue"
                            v-on="inputEvents"
                          />
                        </template>
                      </v-date-picker>

                      <small
                        v-show="showError.dt_fim"
                        id=""
                        class="form-text text-warning"
                        >Indique a data Final do projeto</small
                      >
                    </div>
                    <div class="form-group">
                      <label>Meta Geral </label>
                      <input
                        type="number"
                        class="form-control"
                        placeholder="Meta de Leads, exemplo: 100"
                        min="0"
                        v-model="form.meta"
                      />

                      <small
                        v-show="showError.metaGeral"
                        id=""
                        class="form-text text-warning"
                        >Indique uma meta geral a ser atingida</small
                      >
                    </div>
                  </section>
                </div>
                <div slot="page3">
                  <section>
                    <h3>Descrição</h3>
                    <div class="form-group">
                      <label>Comentários</label>
                      <textarea
                        class="form-control"
                        rows="3"
                        v-model="form.descricao"
                        placeholder="Opcional"
                      ></textarea>
                    </div>
                  </section>
                </div>
                <div slot="page4">
                  <section>
                    <h3>Revisar Projeto</h3>

                    <h5>
                      Nome do Projeto:
                      <span class="ml-2 text-primary">{{ form.name }}</span>
                    </h5>
                    <h5>
                      Tipo:
                      <span class="ml-2 text-primary">{{ form.tipo }}</span>
                    </h5>

                    <h5>
                      Cliente:
                      <span class="ml-2 text-primary">{{ clienteName }}</span>
                    </h5>

                    <h5>
                      Meta Geral
                      <span class="ml-2 text-primary"
                        >{{ form.meta }} <span class="text-info">leads</span>
                      </span>
                    </h5>
                    <h5>
                      Descrição:
                      <span class="ml-2 text-primary">{{
                        form.descricao
                      }}</span>
                    </h5>
                    <h5>
                      Data Inicial:
                      <span class="ml-2 text-primary">{{ dateInicial }}</span>
                    </h5>
                    <h5>
                      Data Final:
                      <span class="ml-2 text-primary">{{ dateEnd }}</span>
                    </h5>
                  </section>
                </div>
              </vue-good-wizard>
            </div>
          </div>
        </div>
      </div>
    </section>


    <!-- <ModalAddStatus v-if="showModalAddStatus" :form="valueToModal" /> -->




    
  </div>
</template>

<script>
// import {
//     mapMutations
// } from "vuex";

import { GoodWizard } from "vue-good-wizard";
import { mapMutations } from "vuex";
import projetoService from "../../../services/projeto.service";
import clienteService from "../../../services/cliente.service";

export default {
  props: {
    getAllList: {},
  },

  components: {
    "vue-good-wizard": GoodWizard,
  },
    
  data() {
    return {
      steps: [
        {
          label: "Criar projeto",
          slot: "page1",
        },
        {
          //   label: "Detalhes ",
          label: "Detalhes",
          slot: "page2",
        },
        {
          label: "Descrição",
          slot: "page3",
        },
        {
          label: "Finish",
          slot: "page4",
          options: {
            // nextDisabled: true,
            finalStepLabel: "Finalizar",
          },
        },
      ],

      tipoProjetos: [
        {
          value: null,
          text: "Selecionar...",
        },
        {
          value: "Base de Dados",
          text: "Base de Dados",
        },
        {
          value: "Prospecção",
          text: "Prospecção",
        },
        
        {
          value: "Projeto de Pesquisa",
          text: "Projeto de Pesquisa",
        },
      ],

      form: {
        name: "",
        cliente: {},
        tipo: null,
        dateStart: new Date(),
        dateEnd: new Date(),
        dateFomatedStart: "",
        dateFomatedEnd: "",
        meta: undefined,
        descricao: "",
      },

      showError: {
        name: false,
        cliente: false,
        tipo: false,
        dt_inicio: false,
        dt_fim: false,
        metaGeral: false,
      },

      options: [],

      clienteName: "",
      dateInicial: undefined,
      dateEnd: undefined,

      loaddingClients: false,

      // showModalAddStatus: false,
      // valueToModal: undefined
    };
  },

  methods: {
    ...mapMutations([
      "mostrarNovoProjetoChangeState",
      "atualizarValorModalEditarStatus",
      "atualizarValorModalEditarRecusas",
    ]),

    nextClicked(currentPage) {
      const _this = this;

      if (currentPage == 0) {
        let name = this.form.name.trim();
        let cliente = this.form.cliente;
        let tipo = this.form.tipo;

        if (!name || name.length < 3) {
          this.showError.name = true;
        }
        if (tipo == null) {
          this.showError.tipo = true;
        }
        if (!cliente.name) {
          _this.showError.cliente = true;
        }

        if (name.length >= 3 && tipo && cliente.name != undefined) {
          this.showError.name = false;
          this.showError.tipo = false;
          _this.showError.cliente = false;

          this.steps[1].label = this.form.name;
          this.steps[2].label = this.form.name;
          this.steps[3].label = this.form.name;
          this.clienteName = cliente.name.name;

          _this.$refs.wizard.goNext(true);
        }
      }

      //pagina array 1 => label "detalhes2

      if (currentPage == 1) {
        let diaStart = this.form.dateStart
          ? this.form.dateStart.getDate()
          : undefined;
        let mesStart = this.form.dateStart
          ? this.form.dateStart.getMonth() + 1
          : undefined;
        let anoStart = this.form.dateStart
          ? this.form.dateStart.getFullYear()
          : undefined;
        diaStart = diaStart <= 9 ? `0${diaStart}` : diaStart;
        mesStart = mesStart <= 9 ? `0${mesStart}` : mesStart;
        let dt_inicio = `${anoStart}-${mesStart}-${diaStart}`; //usar essa variavel

        // ---------------------------
        let diaEnd = this.form.dateEnd
          ? this.form.dateEnd.getDate()
          : undefined;
        let mesEnd = this.form.dateEnd
          ? this.form.dateEnd.getMonth() + 1
          : undefined;
        let anoEnd = this.form.dateEnd
          ? this.form.dateEnd.getFullYear()
          : undefined;
        diaEnd = diaEnd <= 9 ? `0${diaEnd}` : diaEnd;

        mesEnd = mesEnd <= 9 ? `0${mesEnd}` : mesEnd;
        let dt_fim = `${anoEnd}-${mesEnd}-${diaEnd}`; // usar essa variavel

        // console.log("data inicio: " + dt_inicio);
        // console.log("data final   " + dt_fim);

        this.dateInicial = dt_inicio.split("-").reverse().join("-");
        this.dateEnd = dt_fim.split("-").reverse().join("-");

        if (dt_inicio == "undefined-undefined-undefined") {
          _this.showError.dt_inicio = true;
        }
        if (dt_fim == "undefined-undefined-undefined") {
          _this.showError.dt_fim = true;
        }
        if (!_this.form.meta || _this.form.meta <= 0) {
          _this.showError.metaGeral = true;
        }

        if (
          dt_inicio != "undefined-undefined-undefined" &&
          dt_fim != "undefined-undefined-undefined" &&
          _this.form.meta &&
          _this.form.meta > 0
        ) {
          _this.showError.dt_inicio = false;
          _this.showError.dt_fim = false;
          _this.showError.metaGeral = false;
          _this.$refs.wizard.goNext(true);

          _this.form.dateFomatedStart = dt_inicio;
          _this.form.dateFomatedEnd = dt_fim;
        }
      }

      if (currentPage == 2) {
        //pagina array 2 => label "Descrição"
        _this.$refs.wizard.goNext(true);
        //this.teste(this.form)
        //  this.$bvModal.show("my-modal")
        //   this.makeToast("success"); // chama mensagem de sucesso

        // console.log("cheguie no passo 2");

       

      }

      if (currentPage == 3) {
        projetoService
          .addProject(this.form)

          .then(() => {
            //console.log(res);
            
            this.makeToast("success"); // chama mensagem de sucesso
            // this.handlerAbaProjetosNovos(); // ativa evento de click para aba "Projetos Novos"
            this.getAllList(); // atualiza a lista na tela
          //  this.openModalStatus(this.form); // passa dados para modal editarStatus

            this.resetForma(); // limpa formulario
            this.mostrarNovoProjetoChangeState(); // fecha modal
          })
          .catch((erro) => {
            console.log("Erro ao cadastrar projeto " + erro);
          });
      }
    },

    backClicked() {
      return true;
    },

    makeToast(variant = null) {
      if (variant === "success") {
        // setTimeout(() => {
        this.$bvToast.toast(
          `Projeto ${this.form.name.toUpperCase()}  Criado com sucesso`,
          {
            title: `Aviso`,
            variant: variant,
            solid: true,
          }
        );
        // }, 1000);
      }
    },
    handlerAbaProjetosNovos() {
      setTimeout(() => {
        document.getElementById("projetosNovos___BV_tab_button__").click();
      }, 3500);
    },

    resetForma() {
      this.form = {
        name: "",
        cliente: {},
        tipo: null,
        dateStart: new Date(),
        dateEnd: new Date(),
        dateFomatedStart: undefined,
        dateFomatedEnd: undefined,
        meta: undefined,
        descricao: "",
      };
      //volta para pagina inicial
      document.getElementsByClassName("wizard__back pull-left")[0].click();
      document.getElementsByClassName("wizard__back pull-left")[0].click();
      document.getElementsByClassName("wizard__back pull-left")[0].click();
    },

    openModalStatus() {
      //alert()

      /*
      console.log(form);
       this.valueToModal = form


       this.showModalAddStatus= true

        setTimeout(()=>{
          // alert()
          this.$bvModal.show("modal-add-status"); // abre o modal
        })

      */





      
      projetoService
        .getAll()
        .then((res) => {
          console.log(res);
          this.atualizarValorModalEditarStatus(res.data[0]); // enviar favor para o ModalEditarStatus
          this.atualizarValorModalEditarRecusas(res.data[0]); // enviar favor para o ModalEditarRecusas

          setTimeout(() => {
            this.$bvModal.show("my-modal-recusas"); // abre o modal
            this.$bvModal.show("my-modal"); // abre o modal
          }, 1000);
        })
        .catch((e) => {
          console.log("ocorreu um erro ao obter a lista ", e);
        });
        



    },



    findClientes(query) {
      this.loaddingClients = true;
      let params = {
        nome: query,
      };

      clienteService
        .getAll(params)
        .then((res) => {
          let listaClientes = res.data.data;
          //console.log(listaClientes);
          this.options = listaClientes.map((cliente) => {
            return {
              name: cliente.nome,
              id: cliente.id,
            };
          });
          this.loaddingClients = false;
        })
        .catch((erro) => {
          console.log("Erro ao obter LISTA DE CLIENTES " + erro);
        });
    },
  },

  mounted() {
    this.findClientes();
  },
};
</script>

<style lang="scss" scoped>
.section {
  background: #191c24;
  position: fixed;
  width: 40%;
  top: 0;
  display: flex;
  align-items: center;

  bottom: 0;
  right: 0;
  left: 107%;
  z-index: 99;
  /* max-width: 415px;  */
  box-shadow: 0 0 35px -3px #000;
  border-top-left-radius: 6px;

  transition: 0.4s;

  .closerButton {
    position: absolute;
    top: 15px;
    left: 15px;
    z-index: 1;

    span {
      font-size: 24px;
    }

    span:hover {
      opacity: 0.7;
    }
  }
}

.input-date {
  background: #2a3038;
  color: #dcdcdc;
  color: #fff;
}






</style><style lang="scss">
.wizard__steps, .wizard__arrow{
 display: none !important;
}


.wizard__body {
  background: none !important;
}

.multiselect__single {
  background: none;
}

.wizard__step__label {
  text-transform: capitalize;
}

#novoProjeto
  .custom-wizard
  .wizard__body__actions
  a.final-step[data-v-c21d83ca] {
  background: var(--primary);
}

#novoProjeto .custom-wizard .pull-left[data-v-c21d83ca] {
  /* background: none !important; */
  margin-right: 10px;
  /* border-color: var(--secondary) !important; */
  transition: 0.6;

  &:hover {
    opacity: 0.7;
  }
}
</style>
