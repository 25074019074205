<template>
  <b-modal
    id="my-modal-detalhes-projeto"
    class="custom__modal"
    lazy
    :static="true"
    centered
    header-text-variant="light"
    no-close-on-backdrop
    header-class="headerModal"
    content-class="modall"
    hide-footer
    scrollable
    v-model="show"
  >
    <!-- body-bg-variant="light" -->

    <template #modal-title> Detalhes do Projeto </template>

    <div v-if="selectedProject">
      <h4>
        Nome do Projeto:
        <span class="text-primary">{{ selectedProject.nome }} </span>
      </h4>

      <!-- space Status -->

      <div
      class="mt-3"
        v-if="
          selectedProject.projeto_situacao.id === 2 &&
          selectedProject.projeto_tipo.id === 2
        "
      >
        <h5>Progresso:</h5>

        <b-progress
          :max="100"
          class="progress-xl w-100"
          height="1rem"
          show-progress
          style="border-radius: 13px"
        >
          <!-- Aguardando -->

          <b-progress-bar
            variant="info"
            :value="25"
            v-b-tooltip.hover="{ variant: 'info' }"
            :title="`${selectedProject.progresso[0].label} ${selectedProject.progresso[0].quantidade}%`"
          >
            <span
              ><strong
                >{{ selectedProject.progresso[0].quantidade }}%
              </strong></span
            >
          </b-progress-bar>

          <b-progress-bar
            variant="success"
            :value="25"
            v-b-tooltip.hover="{ variant: 'success' }"
            :title="selectedProject.progresso[1].label"
          >
            <span
              ><strong
                >{{ selectedProject.progresso[1].quantidade }}%
              </strong></span
            >
          </b-progress-bar>

          <b-progress-bar
            variant="primary"
            :value="25"
            v-b-tooltip.hover="{ variant: 'primary' }"
            :title="selectedProject.progresso[2].label"
          >
            <span
              ><strong
                >{{ selectedProject.progresso[2].quantidade }}%
              </strong></span
            >
          </b-progress-bar>

          <b-progress-bar
            variant="danger"
            :value="25"
            v-b-tooltip.hover="{ variant: 'danger' }"
            :title="selectedProject.progresso[3].label"
          >
            <span
              ><strong
                >{{ selectedProject.progresso[3].quantidade }}%
              </strong></span
            >
          </b-progress-bar>
        </b-progress>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: ["selectedProject"],
  data() {
    return {
      show: false,
    };
  },

  mounted() {
    // console.log(this.selectedProject);
  },
  methods: {},
  computed: {},

  watch: {},
};
</script>

<style lang="scss">
.custom__modal {
  .modall {
    background: #191c24 !important;
    min-height: 370px;
  }
}
</style>


<style scoped>
</style>