<template>
  <section class="gallery projetos" id="projeto">
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-between align-items-center">
              <h4 class="card-title">Meus Projetos</h4>
              <b-button
                variant="primary"
                class=""
                v-on:click="handlerNewProject"
                v-if="$store.getters['auth/hasPermission']('Editar Projetos')"
              >
                <span><i class="mdi mdi-plus"></i></span> Novo Projeto
                <!-- <span v-else style="width: 110px; display: inline-block">Fechar</span> -->
              </b-button>
            </div>

            <div class="d-sm-flex pb-4 justify-content-between">
              <div class="d-flex align-items-center justify-content-between">
                <h5 class="page-title mb-n2">Projetos Abertos</h5>

                <p
                  class="mt-2 mb-n1 ml-3 text-muted"
                  v-if="todosProjetos.length === 1"
                >
                  {{ todosProjetos.length }} Projeto
                </p>
                <p class="mt-2 mb-n1 ml-3 text-muted" v-else>
                  {{ todosProjetos.length }} Projetos
                </p>
              </div>

              <form
                class="
                  pt-2 pt-md-0
                  align-items-stretch
                  justify-content-between
                  d-none
                "
              >
                <input
                  v-b-tooltip.hover.topleft="{ variant: 'primary' }"
                  title="Buscar por um Projeto existente"
                  type="search"
                  class="form-control"
                  placeholder="Nome do Projeto"
                  v-model.trim="searchTextComouted"
                />

                <!-- <button
                  type="submit"
                  class="btn btn-primary no-wrap ml-0 ml-lg-4 mt-2 mt-lg-0 d-none"
                >
                  Pesquisar
                </button> -->
              </form>
            </div>

            <b-tabs class="tickets-tab-switch">
              <b-tab title="Todos os Projetos">
                <div
                  class="text-center my-2"
                  v-if="showMensageNotFound === true"
                >
                  Não encontrado
                </div>

                <div>
                  <div
                    class="text-center text-white my-2 my-loadding loadding-white-mode"
                    v-if="loadding"
                  >
                    <b-spinner class="align-middle mr-2 "></b-spinner>
                    <strong> Carregando...</strong>
                  </div>

                  <a
                    href="javascript:void(0);"
                    class="tickets-card row d-block"
                    v-for="item in todosProjetos"
                    :key="item.id"
                  >
                    <b-row class="align-items-center">
                      <b-col cols="">
                        <!-- space Name -->

                        <div class="wrapper d-flex align-items-center">
                          <!-- <i class="mdi mdi-information-outline mr-1"></i> -->
                          <h5 class="text-capitalize m-0">{{ item.nome }}</h5>

                          


                          <!-- <span  v-if="item.projeto_situacao.id === 3" class="ml-2 text-danger">Finalizado</span> -->



                        </div>

                        <div class="wrapper text-muted d-none d-md-block">
                          <span> #{{ item.projeto_tipo.descricao }} </span>
                        </div>
                      </b-col>

                      <b-col>

                        <div
                            class="badge badge-primary ml-2"
                            v-if="item.projeto_situacao.id === 1"
                          >
                            Novo
                          </div>

                          <div
                            class="badge badge-info ml-2"
                            v-if="item.projeto_situacao.id === 3"
                          >
                            Finalizado
                          </div>


                        
                      </b-col>

                      <b-col>
                        <!-- space Data Inicio -->
                        <div class="wrapper text-muted d-none d-md-block">
                          <span>{{ item.dt_inicio | dateConverter }}</span>
                        </div>
                        <div class="wrapper text-muted d-none d-md-block">
                          <span>Data Início</span>
                        </div>
                      </b-col>

                      <b-col>
                        <!-- space Data Fim -->
                        <div class="wrapper text-muted d-none d-md-block">
                          <span>{{ item.dt_fim | dateConverter }}</span>
                        </div>
                        <div class="wrapper text-muted d-none d-md-block">
                          <span>Data Final</span>
                        </div>
                      </b-col>

                      

                      <b-col cols="" class="text-center">
                        <!-- space Açoes -->
                      </b-col>

                      <b-col cols="2" class="text-center">
                        <!-- space Data Download/acessarDashboard -->

                        <div class="">
                          <router-link
                            :to="{
                              name: 'ProjetoDashboard',
                              params: { id: item.id },
                            }"
                            class=""
                            v-if="item.projeto_tipo_id == 2"
                          >
                            <i class="mdi mdi-view-dashboard"></i>
                            <span class="ml-1">Acessar Dashboard</span>                 
                           
                          </router-link>

                          <router-link
                            :to="{
                              name: 'ProjetoArquivos',
                              params: { projetoId: item.id },
                            }"
                            class=""
                            v-if="item.projeto_tipo_id == 1"
                            >
                            <i class="mdi mdi-cloud-download"></i>

                            <span class="ml-1">Downloads</span> 
                            </router-link
                          >
                        </div>
                      </b-col>

                      <b-col cols="0" class="">
                        <!-- space Edit Delete -->

                        <b-dropdown
                          class="btn dotted-btn-transparent"
                          text="Dropdown"
                          v-if="
                            $store.getters['auth/hasPermission'](
                              'Editar Projetos'
                            )
                          "
                        >
                          <template slot="button-content">
                            <i class="mdi mdi-dots-vertical"></i>
                          </template>

                          <b-dropdown-item
                            v-on:click="changeStatusProject(item)"
                            v-if="item.projeto_situacao.id === 1"
                          >
                            <i
                              class="ti-control-play mr-2"
                              style="font-size: 1rem"
                            ></i>
                            Iniciar Projeto
                          </b-dropdown-item>

                          <b-dropdown-item
                            v-on:click="finishProject(item)"
                            v-else
                          >
                            <i
                              class="ti-control-stop mr-2"
                              style="font-size: 1rem"
                            ></i>
                            Finalizar Projeto
                          </b-dropdown-item>

                          <b-dropdown-item
                            v-on:click="showSideBarEditar(item)"
                            v-if="item.projeto_situacao.id != 3"
                          >
                            <i
                              class="mdi mdi-pencil mr-2"
                              style="font-size: 1rem"
                            ></i>
                            Editar Projeto
                          </b-dropdown-item>

                           



                          <b-dropdown-item
                            v-on:click="openModalEditStatus(item)"
                            v-if="
                              item.projeto_situacao.id != 3 &&
                              item.projeto_tipo_id === 2
                            "
                          >
                            <i
                              class="icon-settings mr-2"
                              style="font-size: 1rem"
                            ></i>
                            Gerenciar Status
                          </b-dropdown-item>

                          <b-dropdown-item
                            v-on:click="openModalEditRecusas(item)"
                            v-if="
                              item.projeto_situacao.id != 3 &&
                              item.projeto_tipo_id === 2
                            "
                          >
                            <i
                              class="icon-settings mr-2"
                              style="font-size: 1rem"
                            ></i>
                            Gerenciar Motivos de Recusa
                          </b-dropdown-item>

                          <b-dropdown-item
                            :to="{
                              name: 'ProjetoQuestionario',
                              params: { id: item.id },
                            }"
                          >
                            <i
                              class="icon-notebook mr-2"
                              style="font-size: 1rem"
                            ></i>
                            Gerenciar Questionário
                          </b-dropdown-item>

                          <b-dropdown-item
                            v-on:click="openModalDetailsProject(item)"
                            v-if="item.projeto_situacao.id != 3"
                          >
                            <i
                              class="ti-info-alt mr-2"
                              style="font-size: 1rem"
                            ></i>                            
                            Sobre
                          </b-dropdown-item>



                          <b-dropdown-item
                            v-on:click="ModalDeleteProject(item)"
                          >
                            <i
                              class="mdi mdi-delete mr-2"
                              style="font-size: 1rem"
                            ></i>
                            Remover
                          </b-dropdown-item>
                        </b-dropdown>
                      </b-col>
                    </b-row>

                    <!-- other line here below -->
                  </a>
                </div>
              </b-tab>

              <!-- <b-tab title="Projetos Novos " > -->
              <b-tab id="projetosNovos">
                <template #title>
                  <div class="area-notification">
                    <!-- <b-spinner type="grow" small></b-spinner> I'm <i>custom</i> <strong>title</strong> -->
                    <span
                      v-if="
                        todosProjetos.filter(
                          (item) => item.projeto_situacao.id === 1
                        ).length > 0
                      "
                    >
                      {{
                        todosProjetos.filter(
                          (item) => item.projeto_situacao.id === 1
                        ).length
                      }}</span
                    >
                    <!-- <span class="badge badge-outline-success badge-pill">5</span> -->

                    Projetos Novos
                  </div>
                </template>

                <div>
                  <!-- minha data -->

                  <a
                    href="javascript:void(0);"
                    class="tickets-card row d-block"
                    v-for="item in todosProjetos.filter(
                      (item) => item.projeto_situacao.id === 1
                    )"
                    :key="item.id"
                  >
                    <b-row class="align-items-center">
                      <b-col cols="">
                        <!-- space Name -->

                        <div class="wrapper d-flex align-items-center">
                          <h5 class="text-capitalize m-0">{{ item.nome }}</h5>
                        </div>

                        <div class="wrapper text-muted d-none d-md-block">
                          <span> #{{ item.projeto_tipo.descricao }} </span>
                        </div>
                      </b-col>


                      <b-col>

                        <div
                            class="badge badge-primary ml-2"
                            v-if="item.projeto_situacao.id === 1"
                          >
                            Novo
                          </div>

                          <div
                            class="badge badge-info ml-2"
                            v-if="item.projeto_situacao.id === 3"
                          >
                            Finalizado
                          </div>


                        
                      </b-col>




                      <b-col>
                        <!-- space Data Inicio -->
                        <div class="wrapper text-muted d-none d-md-block">
                          <span>{{ item.dt_inicio | dateConverter }}</span>
                        </div>
                        <div class="wrapper text-muted d-none d-md-block">
                          <span>Data Início</span>
                        </div>
                      </b-col>

                      <b-col>
                        <!-- space Data Fim -->
                        <div class="wrapper text-muted d-none d-md-block">
                          <span>{{ item.dt_fim | dateConverter }}</span>
                        </div>
                        <div class="wrapper text-muted d-none d-md-block">
                          <span>Data Final</span>
                        </div>
                      </b-col>

                      

                      <b-col cols="" class="text-center">
                        <!-- space Açoes -->
                      </b-col>

                      <b-col cols="2">
                        <!-- space Data Download/acessarDashboard -->

                        <div class="">
                          <router-link
                            :to="{
                              name: 'ProjetoDashboard',
                              params: { id: item.id },
                            }"
                            class="btn btn-primary w-100"
                            v-if="item.projeto_tipo_id == 2"
                          >
                            <i class="mdi mdi-eye-outline text-primary"></i>
                            Acessar Dashboard
                          </router-link>

                          <router-link
                            :to="{
                              name: 'ProjetoArquivos',
                              params: { projetoId: item.id },
                            }"
                            class="btn btn-primary w-100"
                            v-if="item.projeto_tipo_id == 1"
                            >Downloads</router-link
                          >
                        </div>
                      </b-col>

                      <b-col cols="0" class="">
                        <!-- space Edit Delete -->

                        <b-dropdown
                          class="btn dotted-btn-transparent"
                          text="Dropdown"
                          v-if="
                            $store.getters['auth/hasPermission'](
                              'Editar Projetos'
                            )
                          "
                        >
                          <template slot="button-content">
                            <i class="mdi mdi-dots-vertical"></i>
                          </template>
                          <b-dropdown-item
                            v-on:click="showSideBarEditar(item)"
                            v-if="item.projeto_situacao.id != 3"
                          >
                            <i
                              class="mdi mdi-pencil mr-2"
                              style="font-size: 1rem"
                            ></i>
                            Editar
                          </b-dropdown-item>

                          <b-dropdown-item
                            v-on:click="openModalEditStatus(item)"
                            v-if="
                              item.projeto_situacao.id != 3 &&
                              item.projeto_tipo_id === 2
                            "
                          >
                            <i
                              class="mdi mdi-settings mr-2"
                              style="font-size: 1rem"
                            ></i>
                            Gerenciar Status
                          </b-dropdown-item>

                          <b-dropdown-item
                            v-on:click="openModalEditRecusas(item)"
                            v-if="
                              item.projeto_situacao.id != 3 &&
                              item.projeto_tipo_id === 2
                            "
                          >
                            <i
                              class="mdi mdi-settings mr-2"
                              style="font-size: 1rem"
                            ></i>
                            Gerenciar Motivos de Recusa
                          </b-dropdown-item>

                          <b-dropdown-item
                            v-on:click="ModalDeleteProject(item)"
                          >
                            <i
                              class="mdi mdi-delete mr-2"
                              style="font-size: 1rem"
                            ></i>

                            Remover</b-dropdown-item
                          >
                        </b-dropdown>
                      </b-col>
                    </b-row>
                  </a>
                </div>
              </b-tab>

              <b-tab title="Projetos Em Andamento">
                <div>
                  <a
                    href="javascript:void(0);"
                    class="tickets-card row d-block"
                    v-for="item in todosProjetos.filter(
                      (item) => item.projeto_situacao.id === 2
                    )"
                    :key="item.id"
                  >
                    <b-row class="align-items-center">
                      <b-col cols="">
                        <!-- space Name -->

                        <div class="wrapper d-flex align-items-center">
                          <h5 class="text-capitalize m-0">{{ item.nome }}</h5>
                        </div>

                        <div class="wrapper text-muted d-none d-md-block">
                          <span> #{{ item.projeto_tipo.descricao }} </span>
                        </div>
                      </b-col>

                      <b-col>
                        <!-- space Data Inicio -->
                        <div class="wrapper text-muted d-none d-md-block">
                          <span>{{ item.dt_inicio | dateConverter }}</span>
                        </div>
                        <div class="wrapper text-muted d-none d-md-block">
                          <span>Data Início</span>
                        </div>
                      </b-col>

                      <b-col>
                        <!-- space Data Fim -->
                        <div class="wrapper text-muted d-none d-md-block">
                          <span>{{ item.dt_fim | dateConverter }}</span>
                        </div>
                        <div class="wrapper text-muted d-none d-md-block">
                          <span>Data Final</span>
                        </div>
                      </b-col>

                      <b-col
                        cols="2"
                        class="d-flex justify-content-center align-items-center"
                      >
                        <!-- space Status -->

                        <b-progress
                          :max="max"
                          class="progress-xl w-100"
                          v-if="
                            item.projeto_situacao.id === 2 &&
                            item.projeto_tipo.id === 2
                          "
                          height="1rem"
                          show-progress
                          style="border-radius: 13px"
                        >
                          <b-progress-bar
                            variant="info"
                            :value="values[0]"
                            v-b-tooltip.hover="{ variant: 'info' }"
                            :title="item.progresso[0].label"
                          >
                            <span
                              ><strong
                                >{{ item.progresso[0].quantidade.toFixed(2) }}%
                              </strong></span
                            >
                          </b-progress-bar>

                          <b-progress-bar
                            variant="success"
                            :value="values[1]"
                            v-b-tooltip.hover="{ variant: 'success' }"
                            :title="item.progresso[1].label"
                          >
                            <span
                              ><strong
                                >{{ item.progresso[1].quantidade.toFixed(2)  }}%
                              </strong></span
                            >
                          </b-progress-bar>

                          <b-progress-bar
                            variant="primary"
                            :value="values[2]"
                            v-b-tooltip.hover="{ variant: 'primary' }"
                            :title="item.progresso[2].label"
                          >
                            <span
                              ><strong
                                >{{ item.progresso[2].quantidade.toFixed(2)  }}%
                              </strong></span
                            >
                          </b-progress-bar>

                          <b-progress-bar
                            variant="danger"
                            :value="values[2]"
                            v-b-tooltip.hover="{ variant: 'danger' }"
                            :title="item.progresso[3].label"
                          >
                            <span
                              ><strong
                                >{{ item.progresso[3].quantidade.toFixed(2)  }}%
                              </strong></span
                            >
                          </b-progress-bar>
                        </b-progress>

                        <div
                          class="badge badge-success"
                          v-if="item.projeto_situacao.id === 1"
                        >
                          Novo
                        </div>
                        <div
                          class="badge badge-danger"
                          v-if="item.projeto_situacao.id === 3"
                        >
                          Finalizado
                        </div>
                      </b-col>

                      <b-col cols="" class="text-center">
                        <!-- space Açoes -->
                      </b-col>

                      <b-col cols="2">
                        <!-- space Data Download/acessarDashboard -->

                        <div class="">
                          <router-link
                            :to="{
                              name: 'ProjetoDashboard',
                              params: { id: item.id },
                            }"
                            class="btn btn-primary w-100"
                            v-if="item.projeto_tipo_id == 2"
                          >
                            <i class="mdi mdi-eye-outline text-primary"></i>
                            Acessar Dashboard
                          </router-link>

                          <router-link
                            :to="{
                              name: 'ProjetoArquivos',
                              params: { projetoId: item.id },
                            }"
                            class="btn btn-primary w-100"
                            v-if="item.projeto_tipo_id == 1"
                            >Downloads</router-link
                          >
                        </div>
                      </b-col>

                      <b-col cols="0" class="">
                        <!-- space Edit Delete -->

                        <b-dropdown
                          class="btn dotted-btn-transparent"
                          text="Dropdown"
                          v-if="
                            $store.getters['auth/hasPermission'](
                              'Editar Projetos'
                            )
                          "
                        >
                          <template slot="button-content">
                            <i class="mdi mdi-dots-vertical"></i>
                          </template>
                          <b-dropdown-item
                            v-on:click="showSideBarEditar(item)"
                            v-if="item.projeto_situacao.id != 3"
                          >
                            <i
                              class="mdi mdi-pencil mr-2"
                              style="font-size: 1rem"
                            ></i>
                            Editar
                          </b-dropdown-item>

                          <b-dropdown-item
                            v-on:click="openModalEditStatus(item)"
                            v-if="
                              item.projeto_situacao.id != 3 &&
                              item.projeto_tipo_id === 2
                            "
                          >
                            <i
                              class="mdi mdi-settings mr-2"
                              style="font-size: 1rem"
                            ></i>
                            Gerenciar Status
                          </b-dropdown-item>

                          <b-dropdown-item
                            v-on:click="openModalEditRecusas(item)"
                            v-if="
                              item.projeto_situacao.id != 3 &&
                              item.projeto_tipo_id === 2
                            "
                          >
                            <i
                              class="mdi mdi-settings mr-2"
                              style="font-size: 1rem"
                            ></i>
                            Gerenciar Motivos de Recusa
                          </b-dropdown-item>

                          <b-dropdown-item
                            v-on:click="ModalDeleteProject(item)"
                          >
                            <i
                              class="mdi mdi-delete mr-2"
                              style="font-size: 1rem"
                            ></i>

                            Remover</b-dropdown-item
                          >
                        </b-dropdown>
                      </b-col>
                    </b-row>
                  </a>
                </div>
              </b-tab>

              <b-tab title="Projetos Finalizados">
                <div>
                  <a
                    href="javascript:void(0);"
                    class="tickets-card row d-block"
                    v-for="item in todosProjetos.filter(
                      (item) => item.projeto_situacao.id === 3
                    )"
                    :key="item.id"
                  >
                    <b-row class="align-items-center">
                      <b-col cols="">
                        <!-- space Name -->

                        <div class="wrapper d-flex align-items-center">
                          <h5 class="text-capitalize m-0">{{ item.nome }}</h5>
                        </div>

                        <div class="wrapper text-muted d-none d-md-block">
                          <span> #{{ item.projeto_tipo.descricao }} </span>
                        </div>
                      </b-col>

                      <b-col>
                        <!-- space Data Inicio -->
                        <div class="wrapper text-muted d-none d-md-block">
                          <span>{{ item.dt_inicio | dateConverter }}</span>
                        </div>
                        <div class="wrapper text-muted d-none d-md-block">
                          <span>Data Início</span>
                        </div>
                      </b-col>

                      <b-col>
                        <!-- space Data Fim -->
                        <div class="wrapper text-muted d-none d-md-block">
                          <span>{{ item.dt_fim | dateConverter }}</span>
                        </div>
                        <div class="wrapper text-muted d-none d-md-block">
                          <span>Data Final</span>
                        </div>
                      </b-col>

                      <b-col
                        cols="2"
                        class="d-flex justify-content-center align-items-center"
                      >
                        <!-- space Status -->


                        <b-progress
                          :max="100"
                          class="progress-xl w-100"
                          v-if="item.projeto_situacao.id === 2"
                          height="1rem"
                          show-progress
                          style="border-radius: 13px"
                        >
                          <b-progress-bar
                            variant="info"
                            :value="values[0]"
                            v-b-tooltip.hover="{ variant: 'info' }"
                            title="Aguardando "
                          >
                            <span
                              ><strong>{{ values[0] }}% </strong></span
                            >
                          </b-progress-bar>

                          <b-progress-bar
                            variant="primary"
                            :value="values[1]"
                            v-b-tooltip.hover="{ variant: 'primary' }"
                            title="Oportunidades "
                          >
                            <span
                              ><strong>{{ values[1] }}% </strong></span
                            >
                          </b-progress-bar>

                          <b-progress-bar
                            variant="danger"
                            :value="values[2]"
                            v-b-tooltip.hover="{ variant: 'danger' }"
                            title="Recusas "
                          >
                            <span
                              ><strong>{{ values[2] }}% </strong></span
                            >
                          </b-progress-bar>
                        </b-progress>

                        <div
                          class="badge badge-success"
                          v-if="item.projeto_situacao.id === 1"
                        >
                          Novo
                        </div>
                        <div
                          class="badge badge-outline-danger"
                          v-if="item.projeto_situacao.id === 3"
                        >
                          Finalizado
                        </div>
                      </b-col>

                      <b-col cols="2">
                        <!-- space Data Download/acessarDashboard -->

                        <div class="">
                          <router-link
                            :to="{
                              name: 'ProjetoDashboard',
                              params: { id: item.id },
                            }"
                            class="btn btn-primary w-100"
                            v-if="item.projeto_tipo_id == 2"
                          >
                            <i class="mdi mdi-eye-outline text-primary"></i>
                            Acessar Dashboard
                          </router-link>

                          <router-link
                            :to="{
                              name: 'ProjetoArquivos',
                              params: { projetoId: item.id },
                            }"
                            class="btn btn-primary w-100"
                            v-if="item.projeto_tipo_id == 1"
                            >Downloads</router-link
                          >
                        </div>
                      </b-col>

                      <b-col cols="0" class="">
                        <!-- space Edit Delete -->

                        <b-dropdown
                          class="btn dotted-btn-transparent"
                          text="Dropdown"
                          v-if="
                            $store.getters['auth/hasPermission'](
                              'Editar Projetos'
                            )
                          "
                        >
                          <template slot="button-content">
                            <i class="mdi mdi-dots-vertical"></i>
                          </template>
                          <b-dropdown-item
                            v-on:click="showSideBarEditar(item)"
                            v-if="item.projeto_situacao.id != 3"
                            >Editar
                          </b-dropdown-item>

                          <b-dropdown-item
                            v-on:click="ModalDeleteProject(item)"
                          >
                            <i
                              class="mdi mdi-delete mr-2"
                              style="font-size: 1rem"
                            ></i>

                            Remover</b-dropdown-item
                          >
                        </b-dropdown>
                      </b-col>
                    </b-row>
                  </a>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>

    <NovoProjeto
      v-bind:class="{ top: mostrarNovoProjeto }"
      :getAllList="getAllList"
      v-if="
        $store.getters['auth/hasPermission']('Editar Projetos') &&
        showNewProject
      "
    />

    <b-sidebar
      id="sidebar-right"
      text-variant="light"
      right
      width="40%"
      :lazy="true"
      backdrop-variant="transparent"
      backdrop
      v-if="$store.getters['auth/hasPermission']('Editar Projetos')"
    >
      <EditarProjeto :editItem="editItem" :getAllList="getAllList" />
    </b-sidebar>

    <ModalEditarStatus />
    <ModalEditarRecusas />
    <ModalDetalhesProjeto :selectedProject="selectedProject"/>
  </section>
</template>

<script>
import NovoProjeto from "./partials/NovoProjeto.vue";
import EditarProjeto from "./partials/EditarProjeto.vue";

import projetoService from "../../services/projeto.service";

import { getItem } from "@/hooks/useLocalStorage";

//import clienteService from "../../services/cliente.service";

// import Loading from "./partials/Loading.vue";
// import Amcharts from "./Amcharts.vue";
import { mapState, mapMutations, mapActions } from "vuex";
import ModalEditarStatus from "./partials/ModalEditarStatus.vue";
import ModalEditarRecusas from "./partials/ModalEditarRecusas.vue";
import ModalDetalhesProjeto from "./partials/ModalDetalhesProjeto.vue";

export default {
  components: {
    NovoProjeto,
    EditarProjeto,
    ModalEditarStatus,
    ModalEditarRecusas,
    ModalDetalhesProjeto
  },
  mounted() {
    this.getAllList();
  },

  created: function () {},

  data() {
    return {
      todosProjetos: [],
      todosProjetosCopy: [],

      // "value" e "max" serve para a barra de progresso
      values: [40, 35, 25],
      max: 100,

      editItem: {},
      show: {
        // modalEditarStatus:false,
        // modalEditarRecusas: false
      },
      showNewProject: false,
      searchText: "",
      loadding: true,
      showMensageNotFound: false,
      selectedProject: undefined
    };
  },
  methods: {
    ...mapActions(["atualizarProjetoSelecionadoAction"]),

    ...mapMutations([
      "mostrarNovoProjetoChangeState",
      "atualizarValorModalEditarStatus",
      "atualizarValorModalEditarRecusas",
      "atualizarListenerChangeProjectList",
    ]),
    getAllList() {
      projetoService
        .getAll()
        .then((res) => {
          // console.log(res.data);
          this.todosProjetos = res.data;
          this.todosProjetosCopy = res.data;
          this.loadding = false;
          this.atualizarListenerChangeProjectList();
        })
        .catch((e) => {
          console.log("ocorreu um erro ao obter a lista");
          console.log(e);
        });
    },

    ModalDeleteProject(item) {
      this.$swal({
        title: `${item.nome}`,
        text: "Deseja remover esse projeto?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelVariant: "outline-secondary",
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
      }).then((result) => {
        if (result.value) {
          projetoService
            .deleteProject(item.id)
            .then(() => {
              this.getAllList(); // atualiza a tela

              this.updateProjectSelected(item);
            })
            .catch((e) => {
              console.log("Ocorreu um erro " + e);
            });

          this.$swal(
            "Removido!",
            `<p> <span class="text-capitalize font-weight-bold">${item.nome}</span> Removido! </p> `,
            "success"
          );
        }
      });
    },

    showSideBarEditar(item) {
      // this.show = true;
      this.editItem = item;
      this.$root.$emit("bv::toggle::collapse", "sidebar-right");
    },

    changeStatusProject(item) {
      // console.log(item);
      projetoService
        .changeStatus(item)
        .then(() => {
          this.getAllList(); // atualiza tela
        })
        .catch((erro) => {
          console.log("Erro ao tentar mudar o status do projeto " + erro);
        });
    },
    handlerNewProject() {
      this.showNewProject = true;

      setTimeout(() => {
        this.mostrarNovoProjetoChangeState();
      }, 0);
    },
    openModalEditStatus(item) {
      //alert()
      // console.log(item);
      //alert()

      // this.show.modalEditarStatus = true
      this.atualizarValorModalEditarStatus(item);
      this.$bvModal.show("my-modal");
    },

    openModalEditRecusas(item) {
      // alert()
      this.$bvModal.show("my-modal-recusas");
      this.atualizarValorModalEditarRecusas(item);
    },

    openModalDetailsProject(item){
      this.$bvModal.show("my-modal-detalhes-projeto");
      this.selectedProject = item

    },

    updateProjectSelected(item) {
      const id = getItem("selected-project").id;
      if (item.id === id) {
        this.atualizarProjetoSelecionadoAction(null);
      }
    },
    finishProject(item) {
      if (confirm("Deseja realmente finalizar esse projeto?")) {
        this.changeStatusProject(item);
      }
    },
  },

  computed: {
    ...mapState(["mostrarNovoProjeto"]), // projetos depois pagar dentro do vuex

    currentUser() {
      return this.$store.state.auth.user;
    },

    searchTextComouted: {
      get() {
        return this.searchText;
      },

      set(newValue) {
        //this.searchText = newValue
        // console.log(this.todosProjetos);
        //const __this = this
        this.loadding = true;
        this.showMensageNotFound = false;

        // console.log("mudandooo111");
        let todosProjetos = this.todosProjetosCopy;

        let filtered = todosProjetos.filter((item) => {
          return item.nome.toUpperCase() === newValue.toUpperCase();
        });

        // console.log(filtered);

        // this.showMensageNotFound= false

        if (filtered.length >= 1) {
          this.todosProjetos = filtered;
          this.showMensageNotFound = false;
          this.loadding = false;
          this.searchText = newValue;
          //  alert(123)

          // this.searchText = newValue
        }
        if (filtered.length === 0 && newValue.length > 0) {
          //  alert("executou")

          this.loadding = true;

          // this.showMensageNotFound = true;
          this.showMensageNotFound = false;

          this.searchText = newValue;

          setTimeout(() => {
            if (this.todosProjetos.length === 0) {
              this.showMensageNotFound = true;
            }

            this.loadding = false;

            //alert()
          }, 1000);

          // ------

          //-----

          this.todosProjetos = [];
        }

        if (newValue.length === 0) {
          this.loadding = false;

          this.showMensageNotFound = false;

          this.todosProjetos = this.todosProjetosCopy;

          this.searchText = newValue;
        }

        // return newValue
      },

      //  return this.searchText
    },
  },
  filters: {
    dateConverter(value) {
      if (!value) {
        return "";
      }
      return value.slice(0, 10).split("-").reverse().join("/");
    },
  },

  watch: {
    // teste(){
    //   console.log(this.teste);
    // }
    // valorValor(){
    //   console.log("mudei");
    // }
  },
};
</script>

<style lang="scss" scoped>
.area-notification {
  /* background: red; */
  width: 100%;
  position: relative;

  span {
    position: absolute;
    right: -20px;
    /* background: var(--primary);
        background: #68d757; */

    border-radius: 50%;

    width: 22px;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    top: -30px;

    border: 1px solid var(--success);
    color: var(--success);
    z-index: 0;
    display: none;
  }
}

.top {
  left: 40%;
  left: 60%;
}

.showEditar {
  left: 40%;
  left: 60% !important;
  transition: 1s;
}

.tickets-card {
  justify-content: space-between;
  transition-duration: 0s;
  padding: 5px 15px;
}

.tickets-details {
  flex: 0.6;
  margin-right: 0;
}

.legends {
  justify-content: flex-end;
  position: relative;
  top: -8px;
  padding: 0 20px;
  /* text-align: right; 

  span {
    padding: 3px 10px;
    border-radius: 10px;
    margin: 0 5px;
  } */
}

.no-hover {
  cursor: no-drop;
  /* cursor: wait	; */

  &:hover {
    background: none;
    color: var(--primary);

    background: none;
    color: none;

    i {
      color: var(--primary);
    }
  }
}
</style>

<style lang="scss">
#projeto .tickets-tab-switch .nav-item .nav-link.active {
  /* active */
  background: #0f1015;
}
.nav-tabs {
  .nav-item {
    flex: none !important;
    width: auto;
  }
}
#projeto .nav-pills .nav-item .nav-link,
#projeto .nav-tabs .nav-item .nav-link {
  padding: 0.5rem 1rem !important;
  padding: 10px !important;
  padding: 15px !important;
}

#swal2-title {
  text-transform: capitalize;
}

#sidebar-right {
  box-shadow: 0 0 35px -3px #000 !important;
}

#projeto .progress .progress-bar {
  border-radius: 0;
}
</style>
