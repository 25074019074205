<template>
  <b-modal
    id="my-modal-recusas"
    lazy
    :static="true"
    
    centered
    header-text-variant="light"
    no-close-on-backdrop
    header-class="headerModal"
    content-class="modall"
    hide-footer
    scrollable


    
  >
    <!-- body-bg-variant="light" -->


    <template #modal-title>
      Adicione  <span class="text-primary ">Motivos de Recusas</span>  para o projeto  <span class="text-primary font-weight-bold text-uppercase ">{{valorModalEditarRecusas.nome || valorModalEditarRecusas.name  }}</span>   
    </template>


    <div>
      <div class="add-items d-flex">
        <input
          v-on:keyup.enter="submitStatus"
         ref="input"

          type="text"
          class="form-control todo-list-input"
          placeholder="Adicione um novo motivo de recusa"
          v-model="newStatus"
        />
        <b-button
          @click="submitStatus"
          variant="primary"
          class="add todo-list-add-btn d-flex align-items-center"
          id="add-task"
          >


          <span v-if="!show">
            Salvar
            
          </span> 


          <span v-if="show" class="d-flex">Salvando
            
            <b-spinner small type="grow" class="ml-1" ></b-spinner>
          </span> 
         


          
          </b-button

        >
        <!-- <b-button v-else @click="setEditStatus" variant="outline-secondary" class="add todo-list-add-btn" id="add-task">Alterar</b-button> -->
      </div>

  

      <div class="list-wrapper">
        <ul class="d-flex flex-column-reverse todo-list">
          <li v-for="status in status" :key="status.id">
            <div class="form-check">
              <label class="form-check-label ml-0 text-capitalize">
                {{ status.nome }}
              </label>
            </div>

            <div class="remove d-flex" style="width: auto">
              <!-- <button
                @click.prevent="editStatus(status)"
                class="btn hover"
                v-b-tooltip.hover="{ variant: 'primary' }"
                title="Editar"
              >
                <i class="mdi mdi-pencil text-primary"></i>
              </button> -->

              <button
                @click.prevent="deleteStatus(status)"
                class="btn"
                title="Remover"
                v-b-tooltip.hover="{ variant: 'danger' }"
              >
                <i class="mdi mdi-delete text-danger"></i>
              </button>
            </div>
          </li>
        </ul>    



      </div>

      

      <!-- <div class="d-flex justify-content-end mt-3">
        <button class="btn btn-primary" @click="saveStatus">Salvar</button>
      </div> -->
    </div>

      <!-- <div class="d-flex justify-content-end mt-3 my-btn">
        <button class="btn btn-primary" @click="$bvModal.hide('my-modal-recusas')" >Concluir</button>
      </div> -->


    <div v-if="status.length === 0" class="d-flex justify-content-center mt-5 pt-3 ">
        <h5 class="text-gray">Lista vazia</h5>
    </div>


    



  </b-modal>
</template>

<script>
import { mapState } from "vuex";
import projetoService from '../../../services/projeto.service';
  

export default {
  name: "todo-list",
  data() {
    return {
      // reverseButton: false,

      status: [   
        // {nome: ""} 
      ],
      
      newStatus: "",
      changeStatus: "",
      show: false,
      
    };
  },
  
  mounted() {

    // console.log("montado");
    // console.log(this.valorModalEditarRecusas);


  },
  methods: {
    submitStatus() {
      
      if (!this.newStatus.trim() == "") {
        // if (this.status.includes(  {nome  :this.newStatus.toLowerCase()}  )) {
        if (this.status.some( item=>  {return item.nome == this.newStatus.toLowerCase()}  )) {
          //veridica se existe o status na lista

          alert("Status ja existente");
        } else {
          this.show = true
          //this.status.push({nome:this.newStatus.toLowerCase()});
          this.saveStatus(this.newStatus.toLowerCase())
          this.newStatus = "";
        }
      }
    },
    deleteStatus(status) {
     

      projetoService.deleteRecusas(status.id)
      .then(()=>{
        //console.log(status.nome, " => excluido");
        this.getListStatus()
      })
      .catch((e)=>{
        console.error(status.nome, "=> erro ao excluir ", e);
      })

    },
    
    setEditStatus() {
      // alert()
      this.changeStatus = this.changeStatus.trim();

      if (!this.changeStatus == "") {
        const index = this.status.indexOf(this.newStatus);
        // console.log(index);
        this.status[index] = this.changeStatus;

        //this.status.splice(todoIndex, 1, this.newStatus.toLowerCase() )

        this.changeStatus = "";
        this.newStatus = "";
        // this.reverseButton = false;
      }
    },
    resete() {
      this.changeStatus = "";
      this.newStatus = "";
      // this.reverseButton = false;
    },

    getListStatus(){

     // console.log(this.valorModalEditarRecusas.id);

      projetoService.getListRecusas(this.valorModalEditarRecusas.id)
      .then((res)=>{
       // console.log(res);
        // console.log(res.data);
        this.status = res.data
        //console.log(this.status);
        

      }).catch((e)=>{
        console.log("erro ao obter lista", e);

      })


      
    },


    saveStatus(status){         
         projetoService.addRecusas(status, this.valorModalEditarRecusas.id)
        .then(()=>{
          //console.log(status,  "cadastrado com sucesso");

          this.newStatus = "";
          this.$refs.input.focus()

          this.getListStatus()
          this.show = false          

        }).catch((e)=>{
          console.log("erro ao obter CADASTRAR lista Recusas", e);
          this.show = false


        })

     
    }


  },
  computed: {
    ...mapState(["valorModalEditarRecusas"]),
  },

  watch:{
      valorModalEditarRecusas(){
        //console.log(this.valorModalEditarRecusas);

        this.getListStatus()

      },

      // status(){
      //   console.log(this.status.length);
      // }
  }

};
</script>

<style lang="scss">
#my-modal-recusas {
  /* max-height: 70vh;
    
    overflow-y: scroll; */

  .modall {
    background: #191c24 !important;
    min-height: 370px;
  }

  .headerModal {
  }
}

/* #my-modal:hover #my-modal {
  display: none;
} */
</style>


<style scoped>
  /* .my-btn{
    position: absolute;
    bottom: 15px;
    right: 15px;
  } */
</style>