<template  >
  <b-modal
    id="my-modal"
    centered
    header-text-variant="light"
    no-close-on-backdrop
    header-class="headerModal"
    :content-class="
      !this.$store.state.showWhiteMode
        ? 'modallEditarStatus'
        : 'modallEditarStatusWhite'
    "
    hide-footer
    scrollable
  >
    <!-- body-bg-variant="light" -->

    <template #modal-title>
      Adicione <span class="text-primary font-weight-bold">Status</span> para o
      projeto
      <span class="text-primary font-weight-bold text-uppercase">{{
        valorModalEditarStatus.nome || valorModalEditarStatus.name
      }}</span>
    </template>

    <div>
      <div class="add-items d-flex">
        <input
          v-on:keyup.enter="submitStatus"
          ref="input"
          type="text"
          class="form-control todo-list-input"
          placeholder="Adicione um novo status"
          v-model="newStatus"
        />

        <b-form-select
          v-model="classification"
          :options="classificationOptions"
          size="sm"
        ></b-form-select>

        <b-button
          @click="submitStatus"
          variant="primary"
          class="add todo-list-add-btn d-flex align-items-center"
          id="add-task"
        >
          <span v-if="!show"> Salvar </span>

          <span v-if="show" class="d-flex"
            >Salvando

            <b-spinner small type="grow" class="ml-1"></b-spinner>
          </span>
        </b-button>

        <!-- <b-button v-else @click="setEditStatus" variant="outline-secondary" class="add todo-list-add-btn" id="add-task">Alterar</b-button> -->
      </div>

      <div class="list-wrapper">
        <ul class="d-flex flex-column-reverse todo-list">
          <li
            v-for="status in status"
            :key="status.id"
            style="display: flex; justify-content: space-between !important"
          >
            <div class="form-check">
              <label class="form-check-label ml-0 text-capitalize">
                {{ status.nome }}
              </label>
            </div>

            <!-- <div class="remove d-flex" style="width: auto"> -->
            <div class="d-flex" style="width: auto">
              <!-- <button
                @click.prevent="editStatus(status)"
                class="btn hover"
                v-b-tooltip.hover="{ variant: 'primary' }"
                title="Editar"
              >
                <i class="mdi mdi-pencil text-primary"></i>
              </button> -->

              <!-- <b-form-select
                :options="classificationOptions"
                v-model="classification"
                class="selecte"
                size="sm"
                          
                          

              ></b-form-select> -->

              <!-- <b-form-select v-model="classification" :options="classificationOptions" size="sm" ></b-form-select> -->

              <!-- <select class="form-select form-select-sm" >
                <option selected>Open this select menu</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select> -->

              <button
                @click.prevent="deleteStatus(status)"
                class="btn"
                title="Remover"
                v-b-tooltip.hover="{ variant: 'danger' }"
              >
                <i class="mdi mdi-delete text-danger"></i>
              </button>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <!-- <div class="d-flex justify-content-end mt-3 my-btn">
        <button class="btn btn-primary" @click="$bvModal.hide('my-modal')" >Concluir</button>
      </div> -->

    <div
      v-if="status.length === 0"
      class="d-flex justify-content-center mt-5 pt-3"
    >
      <h5 class="text-gray">Lista vazia</h5>
    </div>
  </b-modal>
</template>

<script>
import { mapState } from "vuex";
import projetoService from "../../../services/projeto.service";

export default {
  name: "todo-list",
  data() {
    return {
      // reverseButton: false,

      // status: [{ nome: "" }],
      status: [],

      newStatus: "",
      changeStatus: "",
      show: false,
      classification: "Sucesso",
      classificationOptions: [
        // { value: null, text: "Selecione" },
        {
          value: "Sucesso",
          text: "Sucesso",
        },
        {
          value: "Falha",
          text: "Falha",
        },
        {
          value: "Em Andamento",
          text: "Em Andamento",
        },
      ],
    };
  },

  mounted() {
    // console.log(this.valorModalEditarStatus);
    // console.log("montado");
  },
  methods: {
    submitStatus() {
      // alert()
      if (!this.newStatus.trim() == "") {
        // if (this.status.includes(  {nome  :this.newStatus.toLowerCase()}  )) {
        if (
          this.status.some((item) => {
            return item.nome == this.newStatus.toLowerCase();
          })
        ) {
          //veridica se existe o status na lista

          alert("Status ja existente");
        } else {
          this.show = true;
          //this.status.push({nome:this.newStatus.toLowerCase()});
          this.saveStatus(this.newStatus.toLowerCase());
        }
      }
    },
    deleteStatus(status) {
      // console.log("delete sendo chamado");

      projetoService
        .deleteStatus(status.id)
        .then(() => {
          //console.log(status.nome, " => excluido");
          this.getListStatus();
        })
        .catch((e) => {
          console.error(status.nome, "=> erro ao excluir ", e);
        });
    },

    setEditStatus() {
      // alert()
      this.changeStatus = this.changeStatus.trim();

      if (!this.changeStatus == "") {
        const index = this.status.indexOf(this.newStatus);
        // console.log(index);
        this.status[index] = this.changeStatus;

        //this.status.splice(todoIndex, 1, this.newStatus.toLowerCase() )

        this.changeStatus = "";
        this.newStatus = "";
        // this.reverseButton = false;
      }
    },
    resete() {
      this.changeStatus = "";
      this.newStatus = "";
      // this.reverseButton = false;
    },

    getListStatus() {
      // console.log(this.valorModalEditarStatus.id);

      projetoService
        .getListStatus(this.valorModalEditarStatus.id)
        .then((res) => {
          // console.log(res);
          // console.log(res.data);
          this.status = res.data;
          // console.log(this.status);
        })
        .catch((e) => {
          console.log("erro ao obter lista", e);
        });
    },

    saveStatus(status) {
      projetoService
        .addStatus(status, this.valorModalEditarStatus.id, this.classification)
        .then(() => {
          //console.log(status,  "cadastrado com sucesso");

          this.newStatus = "";
          this.$refs.input.focus();

          this.getListStatus();
          this.show = false;
        })
        .catch((e) => {
          console.log("erro ao obter CADASTRAR lista status", e);
          this.show = false;
        });
    },
  },
  computed: {
    ...mapState(["valorModalEditarStatus"]),
  },

  watch: {
    valorModalEditarStatus() {
      // console.log(this.valorModalEditarStatus);
      this.getListStatus();
    },

    // status(){
    //   console.log(this.status.length);
    // }
  },
};
</script>

<style lang="scss">
.modallEditarStatus {
  background: #191c24;
  /* min-height: 370px; */

  
}


.modallEditarStatusWhite {
  background: #ffffff;
  border: 1px solid #e4e9f0 !important;

  /* @extend .bg-light; */

  .modal-title{
    color: rgb(0, 0, 0) !important;

  }

  .modal-header {
    border: 1px solid #e4e9f0 !important;
  }

  .modal-header .close {
    color: #000 !important;
  }
  .modal-content {
  }
  .list-wrapper ul li {
    /* border-bottom: 1px solid $colorBorder; */
    border-bottom: 1px solid #e4e9f0;
  }
  .form-control {
    border: 1px solid #e4e9f0 !important;
    color: #000;
    

  }
  .custom-select {
    background-color: #ffffff;
    border: 1px solid #e4e9f0 !important;
    color: #000 !important;
  }

  

  

  


}

#my-modal {
  /* max-height: 70vh;
    
    overflow-y: scroll; */

  .headerModal {
  }
  .custom-select {
    color: #6c7293;
    color: #adadad;
  }
}

/* #my-modal:hover #my-modal {
  display: none;
} */
</style>

<style scoped lang="scss" >
/* .my-btn{
    position: absolute;
    bottom: 15px;
    right: 15px;
  } */

.add-items {
  align-items: center;

  select {
    min-height: 37px;
    width: 150px;
    margin-left: 10px;
  }
  button {
    min-height: 37px;
  }
}
</style>
